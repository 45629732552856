import React from "react"
import ReactTypingEffect from 'react-typing-effect'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Icon from "../components/icon"

import Logo from "../images/logo.png"

function IndexPage() {
  return (
    <Layout>
      <Seo
        keywords={[`Denny`, `Mueller`, `software engineer`, `amateur photographer`, `gamer`]}
        title="Home"
      />

      <section className="text-center">
        <img
          alt="Denny Mueller"
          className="block mx-auto w-4/5 lg:w-1/2 xl:w-1/2"
          src={Logo}
        />

        <h2 className="text-2xl md:text-3xl font-bold inline-block my-8 p-3">
          Hey there! <br />
          <ReactTypingEffect
            text={["software engineer", "wearer of beard", "ruby enthusiast", "メカキーボード コレクタ"]}
            staticText="I'm a"
            speed="100"
            eraseSpeed="50"
          />
          <br />
          from Berlin, Germany.
        </h2>
      </section>

      <section className="body-font">
        <div className="container flex flex-wrap px-5 py-24 mx-auto items-center">
          <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-200">
            <nav className="flex flex-wrap list-none -mb-1">
              <li className="lg:w-1/2 md:w-1/2 mb-1 w-full">
                <a href="https://cv.dennymueller.io" target="_blank" rel="noopener noreferrer"><Icon icon="file-alt" /> CV</a>
              </li>
              <li className="lg:w-1/2 md:w-1/2 mb-1 w-full">
                <a href="https://iota.dennymueller.io" target="_blank" rel="noopener noreferrer"><Icon icon={['fab', 'iota']} /> IOTA Node</a>
              </li>
              <li className="lg:w-1/2 md:w-1/2 mb-1 w-full line-through">
                <a href="#" target="_blank" rel="noopener noreferrer"><Icon icon="camera" /> Photography</a>
              </li>
              <li className="lg:w-1/2 md:w-1/2 mb-1 w-full">
                <a href="https://shimmer.dennymueller.io" target="_blank" rel="noopener noreferrer"><Icon icon={['fab', 'shimmer']} /> Shimmer Node</a>
              </li>
            </nav>
          </div>
          <div className="flex flex-col md:w-1/2 md:pl-12">
            <nav className="flex flex-wrap list-none -mb-1">
              <li className="lg:w-1/4 md:w-1/4 mb-1 w-1/2">
                <a className="" href="https://www.linkedin.com/in/denymm/" target="_blank" rel="noopener noreferrer">
                  <Icon className="fa-2x" icon={['fab', 'linkedin']}/>
                </a>
              </li>
              <li className="lg:w-1/4 md:w-1/4 mb-1 w-1/2">
                <a className="" href="https://github.com/dennym" target="_blank" rel="noopener noreferrer">
                  <Icon className="fa-2x" icon={['fab', 'github']}/>
                </a>
              </li>
              <li className="lg:w-1/4 md:w-1/4 mb-1 w-1/2">
                <a className="" href="https://stackoverflow.com/users/1202324/denny-mueller" target="_blank" rel="noopener noreferrer">
                  <Icon className="fa-2x" icon={['fab', 'stack-overflow']}/>
                </a>
              </li>
              <li className="lg:w-1/4 md:w-1/4 mb-1 w-1/2">
                <a className="" href="https://twitter.com/denym_" target="_blank" rel="noopener noreferrer">
                  <Icon className="fa-2x" icon={['fab', 'twitter']}/>
                </a>
              </li>
            </nav>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
